<template>
  <form-adjustments />
</template>

<script>
  import FormAdjustments from '@/components/forms/form-adjustments'

  export default {
    name: 'create',
    components: { FormAdjustments },
  }
</script>

<style scoped>

</style>
